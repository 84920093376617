.background {
  background-color: var(--main-color);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
}

.loading {
  background-image: url("../../assets/loading.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color);
  display: block;
  width: 100%;
  height: 100%;
}
