:root {
  --main-color: #70c053;
  --out-color: #259238;
  --contrast-color: #061a09;
}

@media (prefers-reduced-motion: no-preference) {
  .scroll-container {
    scroll-behavior: smooth;
  }
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("../public/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("../public/fonts/Inter-Regular.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
