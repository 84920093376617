.message {
  display: block;
  margin: 0;
  padding: 0;
  max-width: 80vw;
}

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  text-align: center;
}
