.button {
  border: none;
  background-color: var(--main-color);
  box-sizing: border-box;
  width: calc(100% - 24px);
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  border-radius: 7.5px;
  padding: 15px 20px;
  font-size: 18px;
  line-height: 18px;
  position: fixed;
  bottom: 24px;
  max-width: 390px;
}
