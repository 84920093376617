.form {
  display: flex;
  max-width: 400px;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  background-color: white;
  height: fit-content;
  position: relative;
  padding-bottom: 62px;
}
